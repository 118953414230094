.page-footer {
  background-color: #fff;
  padding-top: 3em;
  border-bottom: solid 1px #fff;
}
.page-footer__nav {
  display: flex;
  justify-content: space-between;
  padding-bottom: 3em;
  position: relative;
}
.page-footer__nav::after {
  content: "";
  height: 1px;
  width: 100%;
  background-color: var(--color-neutral-600);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
@media (max-width: 1023px) {
  .page-footer__nav::after {
    width: 90%;
  }
}
@media (max-width: 1023px) {
  .page-footer__nav {
    align-content: space-around;
    padding-bottom: 0;
    flex-wrap: wrap;
  }
}
.page-footer__nav__list::before {
  content: "";
  display: block;
  position: relative;
  bottom: 0.75em;
  width: 30px;
  height: 3px;
  border-radius: 2px;
  background-color: var(--color-brand-900);
}
@media (max-width: 1023px) {
  .page-footer__nav__list {
    flex: 50%;
    margin: 1em 0;
    padding: 0 1em;
  }
}
.page-footer__nav__title {
  color: var(--color-neutral-1400);
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.25;
}
@media (max-width: 1023px) {
  .page-footer__nav__title {
    font-size: 0.85em;
  }
}
.page-footer__nav__item {
  margin: 0.75em 0;
  line-height: 1.25;
}
.page-footer__nav__link {
  color: var(--color-neutral-1100);
  text-decoration: none;
  display: flex;
  align-items: center;
  transition: color 0.3s;
  margin: 1em 0;
  font-size: var(--fs-md);
}
.page-footer__nav__link:hover {
  color: var(--color-brand-900);
}
@media (max-width: 1023px) {
  .page-footer__nav__link {
    font-size: 0.7em;
  }
}
.page-footer__nav__picto {
  display: flex;
  padding-right: 0.5em;
}
.page-footer__nav i[class*=__picto] {
  font-size: 1rem;
}
.page-footer__nav i[class*=__picto] > svg {
  fill: var(--color-neutral-1100);
}
.page-footer__nav__img {
  width: 135px;
}
.page-footer__legal {
  display: flex;
  flex-direction: column;
  padding-block: var(--space-48);
}
@media (max-width: 1023px) {
  .page-footer__legal {
    padding-block: var(--space-32);
  }
}
.page-footer__legal__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0.5em auto;
}
.page-footer__legal__item {
  margin: 0 0.75em;
  position: relative;
  line-height: 1;
}
.page-footer__legal__item::after {
  content: "-";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -1em;
}
.page-footer__legal__item:last-child::after {
  display: none;
}
.page-footer__legal__link {
  color: var(--color-neutral-1100);
  font-size: 0.75em;
  text-decoration: none;
  transition: color 0.3s;
}
.page-footer__legal__link:is(button) {
  background-color: transparent;
  background-image: none;
  border: none;
  padding: 0;
  text-decoration: none;
  cursor: pointer;
}
.page-footer__legal__link:hover {
  color: var(--color-brand-900);
}

.theme-dark .page-footer {
  position: relative;
  background-color: var(--color-blue-1200);
  border-bottom: unset;
}
.theme-dark .page-footer::before {
  content: "";
  position: absolute;
  top: 0;
  right: 50%;
  bottom: 0;
  width: 100vw;
  background-color: var(--color-blue-1200);
  transform: translateX(50%);
}
.theme-dark .page-footer__nav *, .theme-dark .page-footer__legal * {
  color: #fff;
}